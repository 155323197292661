import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Loader from '@/Component/Loader.js';
import Swal from 'sweetalert2';
import { LoginSchema } from '../../Validation_Schema';
import { HttpService } from '../../Api/httpService';
import { useLoaderStore } from '../../zustand_store/loader';

const Admin_Login_Page = () => {

    const navigate = useNavigate();
    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state:any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const [initialValues] = useState({ email: "", password: "" });

    const Login = useFormik({
      initialValues,
      validationSchema: LoginSchema,
      onSubmit: (values, action) => {

          SET_LOADER_TRUE();

          HttpService.post(`/api/admin/login`, values)
            .then((res:any) => {
              if (res.status === 200) {
                localStorage.setItem('admin_token',res.data.token );
                navigate("/dashboard/page");
                action.resetForm();
                SET_LOADER_FALSE();
              }
            })
            .catch((err:any) => {
              SET_LOADER_FALSE();
              console.log(err.response);
              if (err.response.data) {
                Swal.fire({
                  title: err.response.data.error,
                  icon: 'error',
                  showConfirmButton: false,
                });
              }
            });
      },
    });
    return (
      <>
        {/* {Isloader ?<Loader />:null} */}

        <div className="hold-transition login-page">
          <div className="login-box">
            <div className="login-logo">
              <div className="row d-flex justify-content-center">
                <img src="/favicon.ico" alt="AdminLTE Logo" className="brand-image img-circle elevation-2"  height="70" width="70"/>
              </div>
              <a href='##'><b>Admin Panel</b></a>
            </div>

            
            <div className="card">
              <div className="card-body login-card-body">
                <p className="login-box-msg mt-2">Sign in to start your session</p>

                <form onSubmit={Login.handleSubmit}>
                  <div className="input-group">
                    <input 
                      className="form-control" 
                      type="email" 
                      placeholder="Email"
                      name="email"
                      value={Login.values.email}
                      onChange={Login.handleChange}
                      onBlur={Login.handleBlur}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-envelope"></span>
                        </div>
                      </div>
                  </div>
                  <div className="d-block mb-3">
                      {Login.errors.email && Login.touched.email ? (
                        <span className="text-danger">{Login.errors.email}</span>
                      ) : null}
                  </div>
                  
                  <div className="input-group">
                    <input 
                      className="form-control" 
                      type="password" 
                      placeholder="Password"
                      name="password"
                      value={Login.values.password}
                      onChange={Login.handleChange}
                      onBlur={Login.handleBlur}
                      autoComplete="on"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-lock"></span>
                        </div>
                      </div>
                  </div>
                  <div className="d-block mb-3">
                      {Login.errors.password && Login.touched.password ? (
                        <span className="text-danger">{Login.errors.password}</span>
                      ) : null}
                  </div>
                  <div className="row mt-1">
                    <button type="submit" className="btn btn-primary btn-block">Login</button>
                  </div>
                </form>
                
              </div>
            </div>
          </div>
        </div>

      </>
    )
}
export default Admin_Login_Page