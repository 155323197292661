import React from 'react'
import { Edit_Project_Schema } from '../../Validation_Schema';
import { HttpService } from '../../Api/httpService';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import useSWR from 'swr';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoaderStore } from '../../zustand_store/loader';

const Portfolio_Categories_Project_Edit = () => {


    const navigate = useNavigate();
    const { state } = useLocation();
    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state: any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const [initialValues, setInitialValues] = useState<any>({ title: "", use_technology: "", image: "", category_id: [], description: "" });
    const [Image, setImage] = useState("");


    // Get Edit data From Api 
    useEffect(() => {
        if (state?.id) {
            SET_LOADER_TRUE();
            HttpService.get(`/api/admin/project/view/${state?.id}`)
                .then((res: any) => {
                    if (res.status === 200) {
                        SET_LOADER_FALSE();
                        setInitialValues({
                            title: res.data.payload[0].project?.title,
                            use_technology: res.data.payload[0].project?.use_technology,
                            image: "",
                            category_id: res.data.payload.map((item: any) => item.category_id),
                            description: res.data.payload[0].project?.description,
                        });
                        setImage(res.data.payload[0].project?.image);
                    }
                })
                .catch((err: any) => {
                    SET_LOADER_FALSE();
                    console.log(err.response);
                    if (err.response.data) {
                        Swal.fire({
                            title: err.response.data.error,
                            icon: 'error',
                            showConfirmButton: false,
                        });
                    }
                });

        } else {
            navigate('/dashboard/portfolio-categories-project');
        }
    }, [SET_LOADER_FALSE, SET_LOADER_TRUE, navigate, state?.id]);

    const { data, isLoading } = useSWR(`/api/admin/portfolio/list`, (url) => HttpService.get(url).then((response: any) => response.data));

    useEffect(() => {
        if (isLoading) {
            SET_LOADER_TRUE();
        } else {
            SET_LOADER_FALSE();
        }
    }, [SET_LOADER_FALSE, SET_LOADER_TRUE, isLoading]);

    const EDIT_PROJECT: any = useFormik({
        initialValues,
        validationSchema: Edit_Project_Schema,
        enableReinitialize: true,
        onSubmit: (values: any, action) => {

            SET_LOADER_TRUE();

            const { category_id } = values;
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('use_technology', values.use_technology);
            formData.append('image', values.image);
            category_id.forEach((key: any) => formData.append("category_id[]", key))
            formData.append('description', values.description);

            HttpService.post(`/api/admin/project/update/${state?.id}`, formData)
                .then((res: any) => {
                    if (res.status === 200) {
                        action.resetForm();
                        SET_LOADER_FALSE();
                        Swal.fire({
                            title: res.data.msg,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {
                            navigate('/dashboard/portfolio-categories-project');
                        }, 2000);
                    }
                })
                .catch((err: any) => {
                    SET_LOADER_FALSE();
                    console.log(err.response);
                    if (err.response.data) {
                        Swal.fire({
                            title: err.response.data.error,
                            icon: 'error',
                            showConfirmButton: false,
                        });
                    }
                });
        },
    });

    const options = data?.payload?.map((item:any) => { return { label: item.name, value: item.id } });

    const intersection = options?.filter((item:any) =>{return initialValues.category_id.includes(`${item.value}`)});

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Project Edit</h1>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button type='button' className="btn btn-info btn-small" onClick={() => navigate('/dashboard/portfolio-categories-project')}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content mx-2">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 card">
                                <div className="card-body">
                                    <form onSubmit={EDIT_PROJECT.handleSubmit}>
                                        <div className="card-body pb-0">

                                            <div className="form-group mb-0">
                                                <label>Title</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Title"
                                                    name="title"
                                                    value={EDIT_PROJECT.values.title}
                                                    onChange={EDIT_PROJECT.handleChange}
                                                    onBlur={EDIT_PROJECT.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {EDIT_PROJECT.errors.title && EDIT_PROJECT.touched.title ? (
                                                    <span className="text-danger">{EDIT_PROJECT.errors.title}</span>
                                                ) : null}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>Use Technology</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Title"
                                                    name="use_technology"
                                                    value={EDIT_PROJECT.values.use_technology}
                                                    onChange={EDIT_PROJECT.handleChange}
                                                    onBlur={EDIT_PROJECT.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {EDIT_PROJECT.errors.use_technology && EDIT_PROJECT.touched.use_technology ? (
                                                    <span className="text-danger">{EDIT_PROJECT.errors.use_technology}</span>
                                                ) : null}
                                            </div>

                                            {Image === "" ?
                                                <div className="form-group mb-0">
                                                    <label htmlFor="exampleInputFile">Select Image</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="exampleInputFile"
                                                                name="image"
                                                                onChange={(e: any) => EDIT_PROJECT.setFieldValue("image", e.target.files[0])}
                                                                accept="image/*"
                                                            />
                                                            <label className="custom-file-label col-sm-12 col-md-5" htmlFor="exampleInputFile">Choose file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="form-group p-0 col-sm-12 col-md-5">
                                                    <div className='position-absolute d-flex justify-content-end w-100' style={{ zIndex: 1 }}>
                                                        <button type="button" className="btn close mt-1 mr-2" aria-label="Close" onClick={() => setImage("")}><span aria-hidden="true">×</span></button>
                                                    </div>
                                                    <img className="img-fluid col-sm-12 m-0 p-0" src={Image} alt="Preview_Image" />
                                                </div>
                                            }

                                            <div className="form-group mb-0">
                                                <label>Select Category_Id</label>
                                                    {intersection?.length ? 
                                                        <Select
                                                            className="col-sm-12 col-md-5 pl-0"
                                                            name="category_id"
                                                            id="category_id"
                                                            instanceId="category_id"
                                                            onChange={option => EDIT_PROJECT.setFieldValue("category_id", option.map((item) => { return `${item.value}` }))}
                                                            isMulti
                                                            options={options}
                                                            defaultValue={intersection}
                                                            isClearable={false}
                                                        />
                                                        :null
                                                    }
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {EDIT_PROJECT.errors.category_id && EDIT_PROJECT.touched.category_id ? (
                                                    <span className="text-danger">{EDIT_PROJECT.errors.category_id}</span>
                                                ) : null}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>Project Description</label>
                                                <textarea
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Description"
                                                    name="description"
                                                    value={EDIT_PROJECT.values.description}
                                                    onChange={EDIT_PROJECT.handleChange}
                                                    onBlur={EDIT_PROJECT.handleBlur}
                                                    rows={5}
                                                    style={{ resize: 'none' }}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {EDIT_PROJECT.errors.description && EDIT_PROJECT.touched.description ? (
                                                    <span className="text-danger">{EDIT_PROJECT.errors.description}</span>
                                                ) : null}
                                            </div>

                                        </div>

                                        <div className="card-footer bg-white mt-0 pt-0">
                                            <button type="submit" className="btn btn-block btn-primary col-sm-1">UPDATE</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default Portfolio_Categories_Project_Edit