import { Link, useLocation } from 'react-router-dom';
import $ from "jquery"

const Admin_Sidebar = () => {
    const {pathname} = useLocation();
    const PAGE = ["/dashboard/page","/dashboard/page/add","/dashboard/page/edit","/dashboard/page/show"];
    const SLIDER = ["/dashboard/slider","/dashboard/slider/add","/dashboard/slider/edit","/dashboard/slider/show"];
    const TECHNOLOGIES = ["/dashboard/technologies","/dashboard/technologies/add","/dashboard/technologies/edit","/dashboard/technologies/show"];
    const SERVICES = ["/dashboard/services","/dashboard/services/add","/dashboard/services/edit","/dashboard/services/show"];
    const PORTFOLIO_CATEGORY = ["/dashboard/portfolio_category","/dashboard/portfolio_category/add","/dashboard/portfolio_category/edit","/dashboard/portfolio_category/show"];
    const PORTFOLIO_CATEGORY_PROJECT = ["/dashboard/portfolio-categories-project","/dashboard/portfolio-categories-project/add","/dashboard/portfolio-categories-project/edit","/dashboard/portfolio-categories-project/show"];

    return (
        <>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">

                <div className="brand-link d-flex justify-content-between">
                    <Link to="/dashboard/page" className="brand-link">
                        <img src="/favicon.ico" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: ".8" }} />
                        <span className="brand-text font-weight-light">Admin Panel</span>
                    </Link>
                    <button type="button" className="text-white mr-1 d-md-none close" onClick={() => $("body").removeClass("sidebar-open")}>×</button>
                </div>

                <div className="sidebar">
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                            <li className="nav-item">
                                <Link to="/dashboard/page" className={`nav-link ${PAGE.includes(pathname) ? "active" : ""}`}>
                                    <i className="nav-icon fas fa-file m-1" />
                                    <p className='ml-2'>Pages</p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/dashboard/slider" className={`nav-link ${SLIDER.includes(pathname) ? "active" : ""}`}>
                                    <i className="nav-icon fas fa-sliders-h m-1" />
                                    <p className='ml-2'>Slider</p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/dashboard/technologies" className={`nav-link ${TECHNOLOGIES.includes(pathname) ? "active" : ""}`}>
                                    <i className="nav-icon fas fa-laptop m-1" />
                                    <p className='ml-2'>Technologies</p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/dashboard/services" className={`nav-link ${SERVICES.includes(pathname) ? "active" : ""}`}>
                                    <i className="nav-icon fas fa-globe m-1" />
                                    <p className='ml-2'>Services</p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/dashboard/get-in-touch-users" className={`nav-link ${pathname === "/dashboard/get-in-touch-users" ? "active" : ""}`}>
                                    <i className="nav-icon fas fa-users m-1" />
                                    <p className="ml-2">Get in Touch Users</p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/dashboard/portfolio_category" className={`nav-link ${PORTFOLIO_CATEGORY.includes(pathname) ? "active" : ""}`}>
                                    <i className="nav-icon fas fa-list-alt m-1" />
                                    <p className="ml-2">Portfolio Category</p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/dashboard/portfolio-categories-project" className={`nav-link d-flex ${PORTFOLIO_CATEGORY_PROJECT.includes(pathname) ? "active" : ""}`}>
                                    <i className="nav-icon fas fa-project-diagram m-1" />
                                    <div className="ml-2">
                                        <p>Projects Details</p>
                                    </div>
                                </Link>
                            </li>
                           
                            <li className="nav-item">
                                <Link to="/dashboard/change-password" className={`nav-link ${pathname === "/dashboard/change-password" ? "active" : ""}`}>
                                    <i className="nav-icon fas fa-lock m-1" />
                                    <p className='ml-2'>Change Password</p>
                                </Link>
                            </li>

                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    )

}

export default Admin_Sidebar