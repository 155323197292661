import { useEffect } from 'react';
import { HttpService } from '../../Api/httpService';
import { useLoaderStore } from '../../zustand_store/loader';
import useSWR from 'swr';
import { useLocation, useNavigate } from 'react-router-dom';

const Portfolio_Categories_Project_Show = () => {

    const navigate = useNavigate();
    const {state} = useLocation();

    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state:any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const { data , isLoading } = useSWR(`/api/admin/project/view/${state?.id}`, (url) => HttpService.get(url).then((response:any) => response.data));
  
    useEffect(() => {
      if(isLoading){
        SET_LOADER_TRUE();
      }else{
        SET_LOADER_FALSE();
      }
    }, [SET_LOADER_FALSE, SET_LOADER_TRUE, isLoading]);

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Project Show</h1>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button type='button' className="btn btn-info btn-small" onClick={() => navigate('/dashboard/portfolio-categories-project')}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content mx-2">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <table className="table table-bordered" style={{ overflowX: 'scroll' }}>
                                            <tbody>
                                                <tr>
                                                    <td className='col-md-2' style={{ width: 0 }}>Title</td>
                                                    <td className='col-md-10'>{data?.payload[0]?.project?.title}</td>
                                                </tr>
                                                <tr>
                                                    <td>Use Technology</td>
                                                    <td>{data?.payload[0]?.project?.use_technology}</td>
                                                </tr>
                                                <tr>
                                                    <td>Image</td>
                                                    <td><img className='img-fluid' src={data?.payload[0]?.project?.image} alt="project img" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Category Id</td>
                                                    <td>{data?.payload?.map((data:any, i:any) => (<div key={i}>{data.category_id},</div>))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Project Description</td>
                                                    <td>{data?.payload[0]?.project?.description}</td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default Portfolio_Categories_Project_Show