import { useState , useEffect } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import useSWR from 'swr';
import Select from 'react-select';
import { useLoaderStore } from '../../zustand_store/loader';
import { HttpService } from '../../Api/httpService';
import { useNavigate } from 'react-router-dom';
import { Add_Project_Schema } from '../../Validation_Schema';

const Portfolio_Categories_Project_Add = () => {
    
    const navigate = useNavigate();
    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state:any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const [initialValues] = useState({ title: "", use_technology: "", image: "", category_id: [], description:"" });

    const { data, isLoading } = useSWR(`/api/admin/portfolio/list`, (url) => HttpService.get(url).then((response: any) => response.data));
    
    useEffect(() => {
      if(isLoading){
        SET_LOADER_TRUE();
      }else{
        SET_LOADER_FALSE();
      }
    }, [SET_LOADER_FALSE, SET_LOADER_TRUE, isLoading]);
    
    const ADD_PROJECT = useFormik({
        initialValues,
        validationSchema: Add_Project_Schema,
        onSubmit: (values, action) => {

            SET_LOADER_TRUE();
  
            const {category_id}=values;
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('use_technology', values.use_technology);
            formData.append('image', values.image);
            category_id.forEach(key => formData.append("category_id[]", key))
            formData.append('description', values.description);
          
            HttpService.post(`/api/admin/project/store`,formData)
                .then((res:any) => {
                    if(res.status===200){   
                        action.resetForm();
                        SET_LOADER_FALSE();
                        Swal.fire({
                            title: res.data.msg,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000
                        });
                        
                        setTimeout(() => {
                            navigate('/dashboard/portfolio-categories-project');
                        }, 2000);
                    }
                })
                .catch((err:any) => {
                    SET_LOADER_FALSE();
                    console.log(err.response);
                    if (err.response.data) {
                        Swal.fire({
                            title: err.response.data.error,
                            icon: 'error',
                            showConfirmButton: false,
                        });
                    }
                });
        },
    });

   
    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Project Add</h1>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button type='button' className="btn btn-info btn-small" onClick={() => navigate('/dashboard/portfolio-categories-project')}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content mx-2">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 card">
                                <div className="card-body">
                                    <form onSubmit={ADD_PROJECT.handleSubmit}>
                                        <div className="card-body pb-0">

                                            <div className="form-group mb-0">
                                                <label>Title</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Title"
                                                    name="title"
                                                    value={ADD_PROJECT.values.title}
                                                    onChange={ADD_PROJECT.handleChange}
                                                    onBlur={ADD_PROJECT.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_PROJECT.errors.title && ADD_PROJECT.touched.title ? (
                                                    <span className="text-danger">{ADD_PROJECT.errors.title}</span>
                                                ) : null}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>Use Technology</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Title"
                                                    name="use_technology"
                                                    value={ADD_PROJECT.values.use_technology}
                                                    onChange={ADD_PROJECT.handleChange}
                                                    onBlur={ADD_PROJECT.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_PROJECT.errors.use_technology && ADD_PROJECT.touched.use_technology ? (
                                                    <span className="text-danger">{ADD_PROJECT.errors.use_technology}</span>
                                                ) : null}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label htmlFor="exampleInputFile">Select Image</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="exampleInputFile"
                                                            name="image"
                                                            onChange={(e:any) => ADD_PROJECT.setFieldValue("image", e.target.files[0])}
                                                            accept="image/*"
                                                        />
                                                        <label className="custom-file-label col-sm-12 col-md-5" htmlFor="exampleInputFile">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_PROJECT.errors.image && ADD_PROJECT.touched.image ? (
                                                    <span className="text-danger">{ADD_PROJECT.errors.image}</span>
                                                ) : null}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>Select Category_Id</label>
                                                <Select
                                                    className="col-sm-12 col-md-5 pl-0"
                                                    name="category_id"
                                                    id="category_id"
                                                    instanceId="category_id"
                                                    onChange={option => ADD_PROJECT.setFieldValue("category_id", option.map((item:any) => { return `${item.value}` }))}
                                                    isMulti
                                                    options={data?.payload?.map((item:any) => { return { label: item.name, value: item.id } })}
                                                    isClearable={false}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_PROJECT.errors.category_id && ADD_PROJECT.touched.category_id ? (
                                                    <span className="text-danger">{ADD_PROJECT.errors.category_id}</span>
                                                ) : null}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>Project Description</label>
                                                <textarea
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Description"
                                                    name="description"
                                                    value={ADD_PROJECT.values.description}
                                                    onChange={ADD_PROJECT.handleChange}
                                                    onBlur={ADD_PROJECT.handleBlur}
                                                    rows={5}
                                                    style={{ resize: 'none' }}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_PROJECT.errors.description && ADD_PROJECT.touched.description ? (
                                                    <span className="text-danger">{ADD_PROJECT.errors.description}</span>
                                                ) : null}
                                            </div>

                                        </div>

                                        <div className="card-footer bg-white mt-0 pt-0">
                                            <button type="submit" className="btn btn-block btn-primary col-sm-1">ADD</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default Portfolio_Categories_Project_Add