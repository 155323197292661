import Index from './Routes';
import { useLoaderStore } from './zustand_store/loader';
import API_LOADER from './Components/loader';

function App(){
    const Loading = useLoaderStore((state:any) => state.is_loader);
    return(<>
        
        {Loading && <API_LOADER/>}
        <Index />

    </>);
}
export default App;