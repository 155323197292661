import { useState } from 'react'
import { Change_Password_Schema } from '../../Validation_Schema';
import { HttpService } from '../../Api/httpService';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { useLoaderStore } from '../../zustand_store/loader';

const Change_Password = () => {

    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state:any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const [initialValues] = useState({old_password:"",password:"",confirm_password:""});

    const Change_Password:any = useFormik({
      initialValues,
      validationSchema: Change_Password_Schema,
      onSubmit: (values:any, action) => {

            SET_LOADER_TRUE();
            HttpService.post(`/api/admin/change-password`,values)
              .then((res:any)=> {
                  if(res.status===200){   
                    action.resetForm();
                    SET_LOADER_FALSE();
                    Swal.fire({
                        title: res.data.msg,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });
                  }
              })
              .catch((err:any) => {
                SET_LOADER_FALSE();
                console.log(err.response);
                if (err.response.data) {
                  Swal.fire({
                    title: err.response.data.error,
                    icon: 'error',
                    showConfirmButton: false,
                  });
                }
              });
      },
    });
    return (
        <>
            <div className="content-wrapper">

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <h1 className="m-0">Change Password</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row d-flex flex-column">
                            <div className="col-12">
                                <div className="card card-primary p-0">
                                    <form onSubmit={Change_Password.handleSubmit}>
                                        <div className="card-body pb-0">
                                            <div className="form-group">
                                                <label>Old Password</label>
                                                <input
                                                    className="form-control col-sm-12 col-md-3"
                                                    type="text"
                                                    placeholder="Enter Old Password"
                                                    autoComplete="off"
                                                    name="old_password"
                                                    value={Change_Password.values.old_password}
                                                    onChange={Change_Password.handleChange}
                                                    onBlur={Change_Password.handleBlur}
                                                />
                                                {Change_Password.errors.old_password && Change_Password.touched.old_password ? (
                                                    <p className="ms-1 mt-1 text-danger">{Change_Password.errors.old_password}</p>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <label>New Password</label>
                                                <input
                                                    className="form-control col-sm-12 col-md-3"
                                                    type="text"
                                                    placeholder="Enter New Password"
                                                    autoComplete="off"
                                                    name="password"
                                                    value={Change_Password.values.password}
                                                    onChange={Change_Password.handleChange}
                                                    onBlur={Change_Password.handleBlur}
                                                />
                                                {Change_Password.errors.password && Change_Password.touched.password ? (
                                                    <p className="ms-1 mt-1 text-danger">{Change_Password.errors.password}</p>
                                                ) : null}
                                            </div>
                                            <div className="form-group">
                                                <label>Confirm Password</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-3"
                                                    placeholder="Enter Confirm Password"
                                                    autoComplete="off"
                                                    name="confirm_password"
                                                    value={Change_Password.values.confirm_password}
                                                    onChange={Change_Password.handleChange}
                                                    onBlur={Change_Password.handleBlur}
                                                />
                                                {Change_Password.errors.confirm_password && Change_Password.touched.confirm_password ? (
                                                    <p className="ms-1 mt-1 text-danger">{Change_Password.errors.confirm_password}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="card-footer bg-white mt-0 pt-0 mb-2">
                                            <button type="submit" className="btn btn-primary">Change Password</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default Change_Password