import { useState } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { useLoaderStore } from '../../zustand_store/loader';
import { HttpService } from '../../Api/httpService';
import { useNavigate } from 'react-router-dom';
import { Add_Services_Schema } from '../../Validation_Schema';

const Services_Add = () => {
    
    const navigate = useNavigate();
    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state:any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const [initialValues] = useState({ icon: "", title: "", description: "" , slug:"", long_description:"", image:"" });

    const ADD_SERVICES:any = useFormik({
        initialValues,
        validationSchema: Add_Services_Schema,
        onSubmit: (values:any, action) => {

            SET_LOADER_TRUE();
            const formData = new FormData();
            Object.keys(values).forEach(key => formData.append(key, values[key]));
          
            HttpService.post(`/api/admin/services/store`,formData)
                .then((res:any) => {
                    if(res.status===200){   
                        action.resetForm();
                        SET_LOADER_FALSE();
                        Swal.fire({
                            title: res.data.msg,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000
                        });
    
                        setTimeout(() => {
                            navigate('/dashboard/services');
                        }, 2000);
                    }
                })
                .catch((err:any) => {
                    SET_LOADER_FALSE();
                    console.log(err.response);
                    if (err.response.data) {
                    Swal.fire({
                        title: err.response.data.error,
                        icon: 'error',
                        showConfirmButton: false,
                    });
                    }
                });
        },
    });
    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Services Add</h1>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button type='button' className="btn btn-info btn-small" onClick={() => navigate('/dashboard/services')}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content mx-2">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 card">
                                <div className="card-body">
                                    <form onSubmit={ADD_SERVICES.handleSubmit}>
                                        <div className="card-body pb-0">
                                            <div className="form-group mb-0">
                                                <label>Icon</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Icon"
                                                    name="icon"
                                                    value={ADD_SERVICES.values.icon}
                                                    onChange={ADD_SERVICES.handleChange}
                                                    onBlur={ADD_SERVICES.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_SERVICES.errors.icon && ADD_SERVICES.touched.icon ? (
                                                    <span className="text-danger">{ADD_SERVICES.errors.icon}</span>
                                                ) : null}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>Title</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Title"
                                                    name="title"
                                                    value={ADD_SERVICES.values.title}
                                                    onChange={ADD_SERVICES.handleChange}
                                                    onBlur={ADD_SERVICES.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_SERVICES.errors.title && ADD_SERVICES.touched.title ? (
                                                    <span className="text-danger">{ADD_SERVICES.errors.title}</span>
                                                ) : null}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>Slug</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Slug"
                                                    name="slug"
                                                    value={ADD_SERVICES.values.slug}
                                                    onChange={ADD_SERVICES.handleChange}
                                                    onBlur={ADD_SERVICES.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_SERVICES.errors.slug && ADD_SERVICES.touched.slug ? (
                                                    <span className="text-danger">{ADD_SERVICES.errors.slug}</span>
                                                ) : null}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>Description</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Description"
                                                    name="description"
                                                    value={ADD_SERVICES.values.description}
                                                    onChange={ADD_SERVICES.handleChange}
                                                    onBlur={ADD_SERVICES.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_SERVICES.errors.description && ADD_SERVICES.touched.description ? (
                                                    <span className="text-danger">{ADD_SERVICES.errors.description}</span>
                                                ) : null}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>Long Description</label>
                                                <textarea
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Long Description"
                                                    name="long_description"
                                                    value={ADD_SERVICES.values.long_description}
                                                    onChange={ADD_SERVICES.handleChange}
                                                    onBlur={ADD_SERVICES.handleBlur}
                                                    rows={5}
                                                    style={{ resize: 'none' }}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_SERVICES.errors.long_description && ADD_SERVICES.touched.long_description ? (
                                                    <span className="text-danger">{ADD_SERVICES.errors.long_description}</span>
                                                ) : null}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label htmlFor="exampleInputFile">Select Image for Services</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="exampleInputFile"
                                                            name="image"
                                                            onChange={(e:any) => ADD_SERVICES.setFieldValue("image", e.target.files[0])}
                                                            accept="image/*"
                                                        />
                                                        <label className="custom-file-label col-sm-12 col-md-5" htmlFor="exampleInputFile">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_SERVICES.errors.image && ADD_SERVICES.touched.image ? (
                                                    <span className="text-danger">{ADD_SERVICES.errors.image}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="card-footer bg-white mt-0 pt-0">
                                            <button type="submit" className="btn btn-block btn-primary col-sm-1">ADD</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default Services_Add