import { useEffect, useState } from 'react';
import { Update_Page_Schema } from '../../Validation_Schema';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoaderStore } from '../../zustand_store/loader';
import { HttpService } from '../../Api/httpService';

const Page_Edit = () => {

    const navigate = useNavigate();
    const {state} = useLocation();
    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state:any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const [initialValues, setInitialValues] = useState({slug: "",title: "",description: "",banner: "",location: "",});
    const [Image, setImage] = useState("");

    useEffect(() => {
        SET_LOADER_TRUE();
        if (state?.id) {
            SET_LOADER_TRUE();
            HttpService.get(`/api/admin/page-edit/${state?.id}`)
                .then((res:any)=> {
                    if (res.status === 200) {
                        setInitialValues({slug: res.data.payload.slug ,title: res.data.payload.title ,description: res.data.payload.description ,banner: "" ,location: res.data.payload.location});
                        setImage(res.data.payload.banner);
                        SET_LOADER_FALSE();
                    }
                })
                .catch((err:any) => {
                    SET_LOADER_FALSE();
                    console.log(err.response);
                    if (err.response.data) {
                        Swal.fire({
                            title: err.response.data.error,
                            icon: 'error',
                            showConfirmButton: false,
                        });
                    }
                });

        } else {
            navigate(-1);
        }
    }, [SET_LOADER_FALSE, SET_LOADER_TRUE, navigate, state?.id]);
   
    const EDIT_PAGE:any = useFormik({
        initialValues,
        validationSchema: Update_Page_Schema,
        enableReinitialize: true,
        onSubmit: (values:any, action) => {

            SET_LOADER_TRUE();

            const formData = new FormData();
            Object.keys(values).forEach((key:any) => formData.append(key, values[key]));

            HttpService.post(`/api/admin/page-update/${state?.id}`, formData)
            .then((res:any) => {
                if(res.status === 200) {
                    action.resetForm();
                    SET_LOADER_FALSE();

                    Swal.fire({
                        title: res.data.msg,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {
                        navigate('/dashboard/page');
                    }, 2000);
                }
            })
            .catch((err:any) => {
                SET_LOADER_FALSE();
                console.log(err.response);
                if (err.response.data) {
                    Swal.fire({
                        title: err.response.data.error,
                        icon: 'error',
                        showConfirmButton: false,
                    });
                }
            });
        },
    });
    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Pages Edit</h1>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button type='button' className="btn btn-info btn-small" onClick={() => navigate(-1)}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content mx-2">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={EDIT_PAGE.handleSubmit}>
                                            <div className="card-body pb-0">

                                                <div className="form-group mb-0">
                                                    <label>Slug</label>
                                                    <input
                                                        type="text"
                                                        className="form-control col-sm-12 col-md-5"
                                                        placeholder="Enter Slug"
                                                        name="slug"
                                                        value={EDIT_PAGE.values.slug}
                                                        onChange={EDIT_PAGE.handleChange}
                                                        onBlur={EDIT_PAGE.handleBlur}
                                                    />
                                                </div>
                                                <div className="d-block mb-3 ml-1 mt-1">
                                                    {EDIT_PAGE.errors.slug && EDIT_PAGE.touched.slug ? (
                                                        <span className="text-danger">{EDIT_PAGE.errors.slug}</span>
                                                    ) : null}
                                                </div>

                                                <div className="form-group mb-0">
                                                    <label>Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control col-sm-12 col-md-5"
                                                        placeholder="Enter Title"
                                                        name="title"
                                                        value={EDIT_PAGE.values.title}
                                                        onChange={EDIT_PAGE.handleChange}
                                                        onBlur={EDIT_PAGE.handleBlur}
                                                    />
                                                </div>
                                                <div className="d-block mb-3 ml-1 mt-1">
                                                    {EDIT_PAGE.errors.title && EDIT_PAGE.touched.title ? (
                                                        <span className="text-danger">{EDIT_PAGE.errors.title}</span>
                                                    ) : null}
                                                </div>

                                                <div className="form-group mb-0">
                                                    <label>Description</label>
                                                    <input
                                                        type="text"
                                                        className="form-control col-sm-12 col-md-5"
                                                        placeholder="Enter Description"
                                                        name="description"
                                                        value={EDIT_PAGE.values.description}
                                                        onChange={EDIT_PAGE.handleChange}
                                                        onBlur={EDIT_PAGE.handleBlur}
                                                    />
                                                </div>
                                                <div className="d-block mb-3 ml-1 mt-1">
                                                    {EDIT_PAGE.errors.description && EDIT_PAGE.touched.description ? (
                                                        <span className="text-danger">{EDIT_PAGE.errors.description}</span>
                                                    ) : null}
                                                </div>



                                                {Image === "" ?
                                                    <div className="form-group mb-0">
                                                        <label htmlFor="exampleInputFile">Select Banner</label>
                                                        <div className="input-group">
                                                            <div className="custom-file">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="exampleInputFile"
                                                                    name="banner"
                                                                    onChange={(e:any) => EDIT_PAGE.setFieldValue("banner", e.target.files[0])}
                                                                    accept="image/*"
                                                                />
                                                                <label className="custom-file-label col-sm-12 col-md-5" htmlFor="exampleInputFile">Choose file</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="form-group p-0 col-sm-12 col-md-5">
                                                        <div className='position-absolute d-flex justify-content-end w-100' style={{ zIndex: 1 }}>
                                                            <button type="button" className="btn close mt-1 mr-2" aria-label="Close" onClick={() => setImage("")}><span aria-hidden="true">×</span></button>
                                                        </div>
                                                        <img className="img-fluid col-sm-12 m-0 p-0" src={Image} alt="Preview_Image" />
                                                    </div>
                                                }


                                                <div className="form-group">
                                                    <label>Location</label>
                                                    <input
                                                        type="text"
                                                        className="form-control col-sm-12 col-md-5"
                                                        placeholder="Enter Location Iframe Url"
                                                        name="location"
                                                        value={EDIT_PAGE.values.location}
                                                        onChange={EDIT_PAGE.handleChange}
                                                        onBlur={EDIT_PAGE.handleBlur}
                                                    />
                                                </div>
                                                <div className="d-block mb-3 ml-1 mt-1">
                                                    {EDIT_PAGE.errors.location && EDIT_PAGE.touched.location ? (
                                                        <span className="text-danger">{EDIT_PAGE.errors.location}</span>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="card-footer bg-white mt-0 pt-0">
                                                <button type="submit" className="btn btn-block btn-primary col-sm-1">UPDATE</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default Page_Edit