import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {

    const navigate=useNavigate();
    
    return (
        <>
            <section className="content">
                <div className="error-page d-flex flex-column text-center" style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <h2 className="headline text-primary">404</h2>
                    
                    <div className="error-content d-flex text-center mx-auto">
                        <h3><i className="fas fa-exclamation-triangle text-primary"/> Oops! Page Not Found.</h3>
                    </div>

                    <div className="text-center">
                        <button type="button" className="btn btn-outline-primary m-2 px-5" onClick={()=>navigate(-1)}>
                            <i className="fas fa-arrow-left mr-1" /> Back
                        </button>
                    </div>

                </div>
            </section>
        </>
    )
}
export default PageNotFound