import React from 'react'

const Admin_Footer = () => {
    return (
        <>
            <footer className="main-footer">
                <strong>COPYRIGHT &copy; {new Date().getFullYear()} &nbsp;<a href="https://www.elancesolution.com" target="_blank" rel="noreferrer">ELANCE SOLUTION</a></strong>
            </footer>
        </>
    )
}

export default Admin_Footer