import * as Yup from "yup";

export const LoginSchema = Yup.object({
  email: Yup.string().max(255,"Email must not be greater than 255 characters.").email("Email must be a valid email address.").required("Email field is required."),
  password: Yup.string().max(255,"Password must not be greater than 255 characters.").required("Password field is required."),
});

export const Change_Password_Schema = Yup.object({
  old_password: Yup.string().max(255,"Old Password must not be greater than 255 characters.").required("Old Password field is required."),
  password: Yup.string().max(255,"New Password must not be greater than 255 characters.").required("New Password field is required."),
  confirm_password: Yup.string().max(255,"Confirm Password must not be greater than 255 characters.").oneOf([Yup.ref("password"), ""], "New Password And Confirm Password Does Not Match.").required("Confirm Password field is required."),
});

export const Add_Page_Schema = Yup.object({
  slug: Yup.string().required("Slug field is required.").max(255,"Slug must not be greater than 255 characters."),
  title: Yup.string().required("Title field is required.").max(255,"Title must not be greater than 255 characters."),
  description: Yup.string().required("Description field is required.").max(255,"Description must not be greater than 255 characters."),
  banner: Yup.mixed().required('File is required'),
  location: Yup.string().required("Location field is required.").max(255,"Location must not be greater than 255 characters."),
});

export const Update_Page_Schema = Yup.object({
  slug: Yup.string().required("Slug field is required.").max(255,"Slug must not be greater than 255 characters."),
  title: Yup.string().required("Title field is required.").max(255,"Title must not be greater than 255 characters."),
  description: Yup.string().required("Description field is required.").max(255,"Description must not be greater than 255 characters."),
  location: Yup.string().required("Location field is required.").max(255,"Location must not be greater than 255 characters."),
});

export const Add_Slider_Schema = Yup.object({
  image: Yup.mixed().required('File is required'),
});

export const Add_Technologis_Slider_Schema = Yup.object({
  name: Yup.string().required("Title field is required.").max(255,"Title must not be greater than 255 characters."),
  image: Yup.mixed().required('File is required'),
});

export const Update_Technologis_Slider_Schema = Yup.object({
  name: Yup.string().required("Title field is required.").max(255,"Title must not be greater than 255 characters."),
});

export const Add_Services_Schema = Yup.object({
  title: Yup.string().required("Title field is required.").max(255,"Title must not be greater than 255 characters."),
  icon: Yup.string().required("Icon field is required.").max(255,"Icon must not be greater than 255 characters."),
  slug: Yup.string().required("Slug field is required.").max(255,"Slug must not be greater than 255 characters."),
  description: Yup.string().required("Description field is required.").max(255,"Description must not be greater than 255 characters."),
  long_description: Yup.string().required("Long Description field is required."), 
  image: Yup.mixed().required('Image is required'),
});

export const Update_Services_Schema = Yup.object({
  title: Yup.string().required("Title field is required.").max(255,"Title must not be greater than 255 characters."),
  icon: Yup.string().required("Icon field is required.").max(255,"Icon must not be greater than 255 characters."),
  slug: Yup.string().required("Slug field is required.").max(255,"Slug must not be greater than 255 characters."),
  description: Yup.string().required("Description field is required.").max(255,"Description must not be greater than 255 characters."),
  long_description: Yup.string().required("Long Description field is required."), 
});

export const Add_Portfolio_Category_Schema = Yup.object({
  name: Yup.string().required("Name field is required.").max(255,"Name must not be greater than 255 characters."),
});

export const Update_Portfolio_Category_Schema = Yup.object({
  name: Yup.string().required("Name field is required.").max(255,"Name must not be greater than 255 characters."),
});

export const Add_Project_Schema = Yup.object({
  title: Yup.string().required("Title field is required."),
  use_technology: Yup.string().required("Use Technology field is required.").max(255,"Use Technology must not be greater than 255 characters."),
  image: Yup.mixed().required('Image is required'),
  category_id: Yup.array().min(1,"Category Id field is required."),
  description:Yup.string().required("Description field is required."),
});

export const Edit_Project_Schema = Yup.object({
  title: Yup.string().required("Title field is required."),
  use_technology: Yup.string().required("Use Technology field is required.").max(255,"Use Technology must not be greater than 255 characters."),
  category_id: Yup.array().min(1,"Category Id field is required."),
});