import { useEffect } from 'react';
import { HttpService } from '../../Api/httpService';
import { useLoaderStore } from '../../zustand_store/loader';
import useSWR from 'swr';
import { useLocation, useNavigate } from 'react-router-dom';

const Page_Show = () => {

    const navigate = useNavigate();
    const {state} = useLocation();

    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state:any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const { data , isLoading } = useSWR(`/api/admin/page-edit/${state?.id}`, (url) => HttpService.get(url).then((response:any) => response.data));
  
    useEffect(() => {
      if(isLoading){
        SET_LOADER_TRUE();
      }else{
        SET_LOADER_FALSE();
      }
    }, [SET_LOADER_FALSE, SET_LOADER_TRUE, isLoading]);

    return (
        <>
            <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Pages Show</h1>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-end">
                    <button type='button' className="btn btn-info btn-small" onClick={() => navigate(-1)}>Back</button>
                    </div>
                </div>
                </div>
            </div>

                <section className="content mx-2">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                            <table className="table table-bordered" style={{overflowX:'scroll'}}>
                                <tbody>
                                <tr>
                                    <td className='col-md-1' style={{width:0}}>Slug</td>
                                    <td className='col-md-11'>{data?.payload?.slug}</td>
                                </tr>
                                <tr>
                                    <td>Title</td>
                                    <td>{data?.payload?.title}</td>
                                </tr>
                                <tr>
                                    <td>Description</td>
                                    <td>{data?.payload?.description}</td>
                                </tr>
                                <tr>
                                    <td>Banner</td>
                                    <td><img className='img-fluid' src={data?.payload?.banner} alt="Banner" /></td>
                                </tr>
                                <tr>
                                    <td>Location</td>
                                    <td><iframe src={data?.payload?.location} style={{border: 0,height: "100%",width: "100%"}} loading="lazy" title='map iframe' /></td>
                                </tr>
                                
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

            </div>
        </>
    )
}
export default Page_Show