import $ from "jquery";
import useMediaQuery from '@mui/material/useMediaQuery';

const Admin_Header = () =>{

    const matches = useMediaQuery('(min-width:767px)');

    const LogOut=()=>{
        localStorage.removeItem('admin_token');
        window.location.reload();
    }
    const Handle_HideShow=()=>{
        if(matches){
            if($("body").hasClass("sidebar-collapse")){
                $("body").removeClass("sidebar-collapse");
            }else{
                $("body").addClass("sidebar-collapse");
            }
        }else{
            if($("body").hasClass("sidebar-open")){
                $("body").addClass("sidebar-closed sidebar-collapse");
                $("body").removeClass("sidebar-open");
            }else{
                $("body").addClass("sidebar-open");
            }
            // sidebar-mini sidebar-closed sidebar-collapse
        }
    }

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        {/* <a className="nav-link" data-widget="pushmenu" href="#" role="button" onClick={Handle_HideShow}><i className="fas fa-bars"></i></a> */}
                        <button type="button" className="btn btn-block btn-light bg-white border-0" onClick={Handle_HideShow}><i className="fas fa-bars"/></button>
                    </li>
                    {/* <li className="nav-item d-none d-sm-inline-block">
                        <a href="index3.html" className="nav-link">Home</a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <a href="#" className="nav-link">Contact</a>
                    </li> */}
                </ul>
                <div className='d-flex justify-content-end w-100'>
                    <button type="button" className="btn btn-primary" onClick={LogOut}>
                        <p style={{fontSize:17,margin:0}}><i className='fas fa-sign-out-alt' />&nbsp;&nbsp;Logout</p>
                    </button>
                </div>
                
            </nav>
        </>
    )

}

export default Admin_Header