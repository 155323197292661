import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import useSWR from 'swr';
import { HttpService } from '../../Api/httpService';
import { useLoaderStore } from '../../zustand_store/loader';
import { useNavigate } from 'react-router-dom';

const Portfolio_Categories_Project_List = () => {
    const navigate = useNavigate();
    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state: any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);

    const { data, mutate, isLoading } = useSWR(`/api/admin/project/list?search=${search}&page=${page}`, (url) => HttpService.get(url).then((response: any) => response.data));

    const HandleDelete = (e: any) => {

        Swal.fire({
            title: 'Do you want to Delete?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#DC3741',
            confirmButtonText: 'Delete',
        }).then((result) => {
            if (result.isConfirmed) {

                SET_LOADER_TRUE();

                HttpService.delete(`/api/admin/project/delete/${e}`)
                    .then((res: any) => {
                        if (res.status === 200) {
                            Swal.fire({
                                title: 'Deleted Sucessfully.',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500
                            });
                            mutate();
                            SET_LOADER_FALSE();
                        }
                    })
                    .catch((err: any) => {
                        SET_LOADER_FALSE();
                        console.log(err.response);
                        if (err.response.data) {
                            Swal.fire({
                                title: err.response.data.error,
                                icon: 'error',
                                showConfirmButton: false,
                            });
                        }
                    });
            }
        });
    }
    
    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Projects</h1>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button className="btn btn-primary btn-small" onClick={() => navigate('/dashboard/portfolio-categories-project/add')}><i className='fas fa-plus' />&nbsp;&nbsp;ADD</button>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 card">
                                <div className="card-header">

                                    <div className="input-group d-flex justify-content-end">
                                        <input className="form-control col-sm-2" type="text" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-search"></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="card-body">
                                    <table id="example2" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th className='text-center'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.payload?.map((item: any, index: any) => (
                                                <tr key={index}>
                                                    <td>{item.title}</td>
                                                    <td className='col-md-2'>
                                                        <div className="d-flex flex-column flex-md-row justify-content-around align-items-center">
                                                            <button type='button' className="btn btn-info btn-small" onClick={() => navigate('/dashboard/portfolio-categories-project/show',{state: { id: item.id }})}><i className="fas fa-eye" /></button>
                                                            <button className="btn btn-primary btn-small" onClick={() => navigate('/dashboard/portfolio-categories-project/edit', {state: { id: item.id } })} ><i className="fas fa-edit" /></button>
                                                            <button type='button' className="btn btn-danger btn-small" onClick={() => HandleDelete(item.id)}><i className="fas fa-trash" /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            {data?.payload?.length <= 0 && (
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div className='d-flex justify-content-center m-2'>
                                                            <h4>No Record Found.</h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}

                                            {isLoading && (
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div className='d-flex justify-content-center m-2'>
                                                            <i className="fas fa-2x fa-sync fa-spin"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            {data?.payload?.length > 0 &&
                                                <tr>
                                                    <th colSpan={3}>
                                                        <ReactPaginate
                                                            previousLabel={'Previous'}
                                                            nextLabel={'Next'}
                                                            onPageChange={(e) => setPage(e.selected + 1)}
                                                            pageCount={Number(data?.totalPage)}
                                                            breakLabel={'...'}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={2}
                                                            activeClassName={'active'}
                                                            pageClassName={'page-item'}
                                                            nextLinkClassName={'page-link'}
                                                            nextClassName={'page-item next'}
                                                            previousClassName={'page-item prev'}
                                                            previousLinkClassName={'page-link'}
                                                            pageLinkClassName={'page-link'}
                                                            breakClassName='page-item'
                                                            breakLinkClassName='page-link'
                                                            containerClassName={'pagination react-paginate pagination-md justify-content-end pr-1 m-0'}
                                                        />
                                                    </th>
                                                </tr>
                                            }
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default Portfolio_Categories_Project_List