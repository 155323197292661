import { Routes, Route, Navigate } from 'react-router-dom';
import AdminLoginPage from '../Pages/Login';
import AdminLayout from '../Layout';
import PageList from '../Pages/Page';
import PageNotFound from '../Pages/PageNotFound';
import PageAdd from '../Pages/Page/add';
import PageEdit from '../Pages/Page/edit';
import PageShow from '../Pages/Page/show';
import SliderList from '../Pages/slider';
import SliderAdd from '../Pages/slider/add';
import SliderEdit from '../Pages/slider/edit';
import SliderShow from '../Pages/slider/show';
import ChangePassword from '../Pages/ChangePassword';
import TechnologiesList from '../Pages/Technologies';
import TechnologiesAdd from '../Pages/Technologies/add';
import TechnologiesEdit from '../Pages/Technologies/edit';
import TechnologiesShow from '../Pages/Technologies/show';
import ServicesList from '../Pages/services';
import ServicesAdd from '../Pages/services/add';
import ServicesEdit from '../Pages/services/edit';
import ServicesShow from '../Pages/services/show';
import GetInTouchUsersList from '../Pages/Get-In-Touch-Users';
import PortfolioCategoryList from '../Pages/PortfolioCategory';
import PortfolioCategoryAdd from '../Pages/PortfolioCategory/add';
import PortfolioCategoryEdit from '../Pages/PortfolioCategory/edit';
import PortfolioCategoryShow from '../Pages/PortfolioCategory/show';
import PortfolioCategoriesProjectList from '../Pages/ProjectsDetails';
import PortfolioCategoriesProjectAdd from '../Pages/ProjectsDetails/add';
import PortfolioCategoriesProjectEdit from '../Pages/ProjectsDetails/edit';
import PortfolioCategoriesProjectShow from '../Pages/ProjectsDetails/show';
import AdminLoginMiddleware from '../Middleware/Admin_Login_Middleware';
import AdminDashboardMiddleware from '../Middleware/Admin_Dashboard_Middleware';

function Index() {

    return (
        <Routes>
            <Route element={<AdminLoginMiddleware />}>
                <Route path='/' element={<AdminLoginPage/>} />
                <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Route>
            <Route element={<AdminDashboardMiddleware />}>
                <Route element={<AdminLayout />}>
        
                  <Route path="dashboard/page" element={<PageList />} />
                  <Route path="dashboard/page/add" element={<PageAdd />} />
                  <Route path="dashboard/page/edit" element={<PageEdit />} />
                  <Route path="dashboard/page/show" element={<PageShow />} />
        
                  <Route path="dashboard/slider" element={<SliderList />} />
                  <Route path="dashboard/slider/add" element={<SliderAdd />} />
                  <Route path="dashboard/slider/edit" element={<SliderEdit />} />
                  <Route path="dashboard/slider/show" element={<SliderShow />} />
        
                  <Route path="dashboard/technologies" element={<TechnologiesList />} />
                  <Route path="dashboard/technologies/add" element={<TechnologiesAdd />} />
                  <Route path="dashboard/technologies/edit" element={<TechnologiesEdit />} />
                  <Route path="dashboard/technologies/show" element={<TechnologiesShow />} />
        
                  <Route path="dashboard/services" element={<ServicesList />} />
                  <Route path="dashboard/services/add" element={<ServicesAdd />} />
                  <Route path="dashboard/services/edit" element={<ServicesEdit />} />
                  <Route path="dashboard/services/show" element={<ServicesShow />} />
        
                  <Route path="dashboard/get-in-touch-users" element={<GetInTouchUsersList />} />
        
                  <Route path="dashboard/portfolio_category" element={<PortfolioCategoryList />} />
                  <Route path="dashboard/portfolio_category/add" element={<PortfolioCategoryAdd />} />
                  <Route path="dashboard/portfolio_category/edit" element={<PortfolioCategoryEdit />} />
                  <Route path="dashboard/portfolio_category/show" element={<PortfolioCategoryShow />} />
        
                  <Route path="dashboard/portfolio-categories-project" element={<PortfolioCategoriesProjectList />} />
                  <Route path="dashboard/portfolio-categories-project/add" element={<PortfolioCategoriesProjectAdd />} />
                  <Route path="dashboard/portfolio-categories-project/edit" element={<PortfolioCategoriesProjectEdit />} />
                  <Route path="dashboard/portfolio-categories-project/show" element={<PortfolioCategoriesProjectShow />} />
        
                  <Route path="/dashboard/change-password" element={<ChangePassword />} />
        
                  <Route path="*" element={<PageNotFound />} />
                </Route>
            </Route>
        </Routes>
    )
};
export default Index