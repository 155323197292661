import { Outlet } from 'react-router-dom';
import AdminHeader from "./header";
import AdminSidebar from "./sidebar";
import AdminFooter from './footer';

const AdminLayout = () =>{

    return (
        <>
          <div className="wrapper">
            <AdminHeader />
            <AdminSidebar />
            <Outlet/>
            <AdminFooter />
          </div>
        </>
      )

} 

export default AdminLayout