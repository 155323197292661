import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLoaderStore } from '../../zustand_store/loader';
import { HttpService } from '../../Api/httpService';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { Add_Technologis_Slider_Schema } from '../../Validation_Schema';

const Technologies_Add = () => {

    const navigate = useNavigate();
    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state:any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const [initialValues] = useState({name: "",image: ""});

    const ADD_TECHNOLOGIS_SLIDER:any = useFormik({
        initialValues,
        validationSchema: Add_Technologis_Slider_Schema,
        onSubmit: (values:any, action) => {

            SET_LOADER_TRUE();
            const formData = new FormData();
            Object.keys(values).forEach(key => formData.append(key, values[key]));
          
            HttpService.post(`/api/admin/technology/store`,formData)
              .then((res:any) => {
                  if(res.status===200){   
                        SET_LOADER_FALSE();
                        action.resetForm();
                        Swal.fire({
                            title: res.data.msg,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000
                        });
    
                        setTimeout(() => {
                            navigate('/dashboard/technologies');
                        }, 2000);
                  }
              })
              .catch((err:any) => {
                SET_LOADER_FALSE();
                console.log(err.response);
                if (err.response.data) {
                    Swal.fire({
                        title: err.response.data.error,
                        icon: 'error',
                        showConfirmButton: false,
                    });
                }
            });
        },
    });
    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Technologis Slider Add</h1>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button type='button' className="btn btn-info btn-small" onClick={() => navigate('/dashboard/technologies')}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content mx-2">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 card">
                                <div className="card-body">
                                    <form onSubmit={ADD_TECHNOLOGIS_SLIDER.handleSubmit}>
                                        <div className="card-body pb-0">

                                            <div className="form-group mb-0">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Name"
                                                    name="name"
                                                    value={ADD_TECHNOLOGIS_SLIDER.values.name}
                                                    onChange={ADD_TECHNOLOGIS_SLIDER.handleChange}
                                                    onBlur={ADD_TECHNOLOGIS_SLIDER.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_TECHNOLOGIS_SLIDER.errors.name && ADD_TECHNOLOGIS_SLIDER.touched.name ? (
                                                    <span className="text-danger">{ADD_TECHNOLOGIS_SLIDER.errors.name}</span>
                                                ) : null}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label htmlFor="exampleInputFile">Select Image for Technologis Slider</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="exampleInputFile"
                                                            name="image"
                                                            onChange={(e:any) => ADD_TECHNOLOGIS_SLIDER.setFieldValue("image", e.target.files[0])}
                                                            accept="image/*"
                                                        />
                                                        <label className="custom-file-label col-sm-12 col-md-5" htmlFor="exampleInputFile">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_TECHNOLOGIS_SLIDER.errors.image && ADD_TECHNOLOGIS_SLIDER.touched.image ? (
                                                    <span className="text-danger">{ADD_TECHNOLOGIS_SLIDER.errors.image}</span>
                                                ) : null}
                                            </div>

                                        </div>

                                        <div className="card-footer bg-white mt-0 pt-0">
                                            <button type="submit" className="btn btn-block btn-primary col-sm-1">ADD</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default Technologies_Add