import { useState } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { Add_Page_Schema } from '../../Validation_Schema';
import { useLoaderStore } from '../../zustand_store/loader';
import { HttpService } from '../../Api/httpService';
import { useNavigate } from 'react-router-dom';

const Page_Add = () => {

    const navigate = useNavigate();
    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state:any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const [initialValues] = useState({ slug: "", title: "", description: "", banner: "", location: "" });
    
    const ADD_PAGE:any = useFormik({
        initialValues,
        validationSchema: Add_Page_Schema,
        onSubmit: (values:any, action) => {
            
            SET_LOADER_TRUE();

            const formData = new FormData();
            Object.keys(values).forEach((key:any) => formData.append(key, values[key]));

            HttpService.post(`/api/admin/page-store`, formData)
            .then((res:any) => {
                if(res.status === 200) {
                    action.resetForm();
                    SET_LOADER_FALSE();

                    Swal.fire({
                        title: res.data.msg,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {
                    navigate('/dashboard/page');
                    }, 2000);
                }
            })
            .catch((err:any) => {
                SET_LOADER_FALSE();
                console.log(err.response);
                if (err.response.data) {
                Swal.fire({
                    title: err.response.data.error,
                    icon: 'error',
                    showConfirmButton: false,
                });
                }
            });
        },
    });

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Page Add</h1>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button type='button' className="btn btn-info btn-small" onClick={() => navigate(-1)}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content mx-2">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 card">
                                <div className="card-body">
                                    <form onSubmit={ADD_PAGE.handleSubmit}>
                                        <div className="card-body pb-0">

                                            <div className="form-group mb-0">
                                                <label>Slug</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Slug"
                                                    name="slug"
                                                    value={ADD_PAGE.values.slug}
                                                    onChange={ADD_PAGE.handleChange}
                                                    onBlur={ADD_PAGE.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_PAGE.errors.slug && ADD_PAGE.touched.slug && (<span className="text-danger">{ADD_PAGE.errors.slug}</span>)}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>Title</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Title"
                                                    name="title"
                                                    value={ADD_PAGE.values.title}
                                                    onChange={ADD_PAGE.handleChange}
                                                    onBlur={ADD_PAGE.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_PAGE.errors.title && ADD_PAGE.touched.title && (<span className="text-danger">{ADD_PAGE.errors.title}</span>)}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label>Description</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Description"
                                                    name="description"
                                                    value={ADD_PAGE.values.description}
                                                    onChange={ADD_PAGE.handleChange}
                                                    onBlur={ADD_PAGE.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_PAGE.errors.description && ADD_PAGE.touched.description && (<span className="text-danger">{ADD_PAGE.errors.description}</span>)}
                                            </div>

                                            <div className="form-group mb-0">
                                                <label htmlFor="exampleInputFile">Select Banner</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="exampleInputFile"
                                                            name="banner"
                                                            onChange={(e:any) => ADD_PAGE.setFieldValue("banner", e.target.files[0])}
                                                            accept="image/*"
                                                        />
                                                        <label className="custom-file-label col-sm-12 col-md-5" htmlFor="exampleInputFile">Choose file</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_PAGE.errors.banner && ADD_PAGE.touched.banner && (<span className="text-danger">{ADD_PAGE.errors.banner}</span>)}
                                            </div>

                                            <div className="form-group">
                                                <label>Location</label>
                                                <input
                                                    type="text"
                                                    className="form-control col-sm-12 col-md-5"
                                                    placeholder="Enter Location Iframe Url"
                                                    name="location"
                                                    value={ADD_PAGE.values.location}
                                                    onChange={ADD_PAGE.handleChange}
                                                    onBlur={ADD_PAGE.handleBlur}
                                                />
                                            </div>
                                            <div className="d-block mb-3 ml-1 mt-1">
                                                {ADD_PAGE.errors.location && ADD_PAGE.touched.location && (<span className="text-danger">{ADD_PAGE.errors.location}</span>)}
                                            </div>
                                        </div>

                                        <div className="card-footer bg-white mt-0 pt-0">
                                            <button type="submit" className="btn btn-block btn-primary col-sm-1">ADD</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default Page_Add