import { Box, Modal, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import useSWR from 'swr';
import { HttpService } from '../../Api/httpService';

const Get_In_Touch_Users_List = () => {
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [one_user_data, setOne_User_Data] = useState<any>({});

    const { data, isLoading } = useSWR(`/api/admin/user-list?search=${search}&page=${page}`, (url) => HttpService.get(url).then((response: any) => response.data));

    const Show_User_Details = (data: any) => {
        setOne_User_Data(data);
        setOpen(true);
    }

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <h1 className="m-0">Get-in-Touch Users</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 card">
                                <div className="card-header">

                                    <div className="input-group d-flex justify-content-end">
                                        <input className="form-control col-sm-2" type="text" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-search"></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <Modal open={open} onClose={() => setOpen(false)} disableAutoFocus={true}>
                                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                        <Card sx={{ minWidth: 275 }}>
                                            <CardHeader title="Users Details" style={{ textAlign: 'center', paddingBottom: 0 }} />
                                            <CardContent>
                                                <Grid container spacing={0} maxWidth={500}>
                                                    <Grid item sm={12} lg={2} md={2} justifyContent="start" my={1}>
                                                        <Typography
                                                            fontWeight={700}
                                                            fontSize={'16px'}
                                                            lineHeight={'24px'}
                                                            textAlign="left"
                                                        >
                                                            Name
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item sm={12} lg={9} md={9} justifyContent="start" my={1}>
                                                        <Typography
                                                            fontWeight={700}
                                                            fontSize={'16px'}
                                                            lineHeight={'24px'}
                                                            textAlign="left"
                                                        >
                                                            : {one_user_data && one_user_data.name}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item sm={12} lg={2} md={2} justifyContent="start" my={1}>
                                                        <Typography
                                                            fontWeight={700}
                                                            fontSize={'16px'}
                                                            lineHeight={'24px'}
                                                            textAlign="left"
                                                        >
                                                            Email
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item sm={12} lg={9} md={9} justifyContent="start" my={1}>
                                                        <Typography
                                                            fontWeight={700}
                                                            fontSize={'16px'}
                                                            lineHeight={'24px'}
                                                            textAlign="left"
                                                        >
                                                            : {one_user_data && one_user_data.email}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item sm={12} lg={2} md={2} justifyContent="start" my={1}>
                                                        <Typography
                                                            fontWeight={700}
                                                            fontSize={'16px'}
                                                            lineHeight={'24px'}
                                                            textAlign="left"
                                                        >
                                                            Subject
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item sm={12} lg={9} md={9} justifyContent="start" my={1}>
                                                        <Typography
                                                            fontWeight={700}
                                                            fontSize={'16px'}
                                                            lineHeight={'24px'}
                                                            textAlign="left"
                                                            sx={{ wordWrap: 'break-word' }}
                                                        >
                                                          :  {one_user_data && one_user_data.subject}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item sm={12} lg={2} md={2} justifyContent="start" my={1}>
                                                        <Typography
                                                            fontWeight={700}
                                                            fontSize={'16px'}
                                                            lineHeight={'24px'}
                                                            textAlign="left"
                                                        >
                                                            Message
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item sm={12} lg={9} md={9} justifyContent="start" my={1}>
                                                        <Typography
                                                            fontWeight={700}
                                                            fontSize={'16px'}
                                                            lineHeight={'24px'}
                                                            textAlign="left"
                                                            sx={{ wordWrap: 'break-word' }}
                                                        >
                                                            : {one_user_data && one_user_data.message}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item sm={12} lg={2} md={2} justifyContent="start" my={1}>
                                                        <Typography
                                                            fontWeight={700}
                                                            fontSize={'16px'}
                                                            lineHeight={'24px'}
                                                            textAlign="left"
                                                        >
                                                            Created Date
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item sm={12} lg={9} md={9} justifyContent="start" my={1}>
                                                        <Typography
                                                            fontWeight={700}
                                                            fontSize={'16px'}
                                                            lineHeight={'24px'}
                                                            textAlign="left"
                                                        >
                                                            : {one_user_data && one_user_data.created_at}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Modal>

                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover mt-1">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Created Date</th>
                                                    <th className='text-center'>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.payload?.map((item: any, index: any) => (
                                                    <tr key={index}>
                                                        <td>{item.name}</td>
                                                        <td>{item.email}</td>
                                                        <td>{moment(item.created_at).format('LLL')}</td>
                                                        <td className="col-md-2">
                                                            <div className="d-flex flex-column flex-md-row justify-content-around align-items-center">
                                                                <button type='button' className="btn btn-info btn-small col-md-auto col-sm-12 my-1 mx-md-3" onClick={() => Show_User_Details(item)}><i className="fas fa-eye" /></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                                {data?.payload?.length <= 0 && (
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <div className='d-flex justify-content-center m-2'>
                                                                <h4>No Record Found.</h4>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}

                                                {isLoading && (
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <div className='d-flex justify-content-center m-2'>
                                                                <i className="fas fa-2x fa-sync fa-spin"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                            <tfoot>
                                                {data?.payload?.length > 0 &&
                                                    <tr>
                                                        <th colSpan={4}>
                                                            <ReactPaginate
                                                                previousLabel={'Previous'}
                                                                nextLabel={'Next'}
                                                                onPageChange={(e) => setPage(e.selected + 1)}
                                                                pageCount={Number(data?.totalPage)}
                                                                breakLabel={'...'}
                                                                pageRangeDisplayed={2}
                                                                marginPagesDisplayed={2}
                                                                activeClassName={'active'}
                                                                pageClassName={'page-item'}
                                                                nextLinkClassName={'page-link'}
                                                                nextClassName={'page-item next'}
                                                                previousClassName={'page-item prev'}
                                                                previousLinkClassName={'page-link'}
                                                                pageLinkClassName={'page-link'}
                                                                breakClassName='page-item'
                                                                breakLinkClassName='page-link'
                                                                containerClassName={'pagination react-paginate pagination-md justify-content-end pr-1 m-0'}
                                                            />
                                                        </th>
                                                    </tr>
                                                }
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default Get_In_Touch_Users_List