import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoaderStore } from '../../zustand_store/loader';
import { HttpService } from '../../Api/httpService';
import { Update_Services_Schema } from '../../Validation_Schema';

const Services_Edit = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state: any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const [initialValues, setInitialValues] = useState({ icon: "", title: "", description: "", slug: "", long_description: "", image: "" });
    const [Image, setImage] = useState("");

    useEffect(() => {
        if (state?.id) {
            SET_LOADER_TRUE();
            HttpService.get(`/api/admin/services/edit/${state?.id}`)
                .then((res: any) => {
                    if (res.status === 200) {
                        SET_LOADER_FALSE();
                        setInitialValues({
                            icon: res.data.payload.icon,
                            title: res.data.payload.title,
                            description: res.data.payload.description,
                            slug: res.data.payload.slug,
                            long_description: res.data.payload.long_description,
                            image: ""
                        });
                        setImage(res.data.payload.image);
                    }
                })
                .catch((err: any) => {
                    SET_LOADER_FALSE();
                    console.log(err.response);
                    if (err.response.data) {
                        Swal.fire({
                            title: err.response.data.error,
                            icon: 'error',
                            showConfirmButton: false,
                        });
                    }
                });

        } else {
            navigate('/dashboard/services');
        }
    }, [SET_LOADER_FALSE, SET_LOADER_TRUE, navigate, state?.id]);

    const EDIT_SERVICES: any = useFormik({
        initialValues,
        validationSchema: Update_Services_Schema,
        enableReinitialize: true,
        onSubmit: (values: any, action: any) => {

            SET_LOADER_TRUE();
            const formData = new FormData();
            Object.keys(values).forEach(key => formData.append(key, values[key]));

            HttpService.post(`/api/admin/services/update/${state?.id}`, formData)
                .then((res: any) => {
                    if (res.status === 200) {
                        action.resetForm();
                        SET_LOADER_FALSE();
                        Swal.fire({
                            title: res.data.msg,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {
                            navigate('/dashboard/services');
                        }, 2000);
                    }
                })
                .catch((err: any) => {
                    SET_LOADER_FALSE();
                    console.log(err.response);
                    if (err.response.data) {
                        Swal.fire({
                            title: err.response.data.error,
                            icon: 'error',
                            showConfirmButton: false,
                        });
                    }
                });
        },
    });

    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Services Edit</h1>
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                <button type='button' className="btn btn-info btn-small" onClick={() => navigate('/dashboard/services')}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content mx-2">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={EDIT_SERVICES.handleSubmit}>
                                            <div className="card-body pb-0">
                                                <div className="form-group mb-0">
                                                    <label>Icon</label>
                                                    <input
                                                        type="text"
                                                        className="form-control col-sm-12 col-md-5"
                                                        placeholder="Enter Icon"
                                                        name="icon"
                                                        value={EDIT_SERVICES.values.icon}
                                                        onChange={EDIT_SERVICES.handleChange}
                                                        onBlur={EDIT_SERVICES.handleBlur}
                                                    />
                                                </div>
                                                <div className="d-block mb-3 ml-1 mt-1">
                                                    {EDIT_SERVICES.errors.icon && EDIT_SERVICES.touched.icon ? (
                                                        <span className="text-danger">{EDIT_SERVICES.errors.icon}</span>
                                                    ) : null}
                                                </div>

                                                <div className="form-group mb-0">
                                                    <label>Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control col-sm-12 col-md-5"
                                                        placeholder="Enter Title"
                                                        name="title"
                                                        value={EDIT_SERVICES.values.title}
                                                        onChange={EDIT_SERVICES.handleChange}
                                                        onBlur={EDIT_SERVICES.handleBlur}
                                                    />
                                                </div>
                                                <div className="d-block mb-3 ml-1 mt-1">
                                                    {EDIT_SERVICES.errors.title && EDIT_SERVICES.touched.title ? (
                                                        <span className="text-danger">{EDIT_SERVICES.errors.title}</span>
                                                    ) : null}
                                                </div>

                                                <div className="form-group mb-0">
                                                    <label>Slug</label>
                                                    <input
                                                        type="text"
                                                        className="form-control col-sm-12 col-md-5"
                                                        placeholder="Enter Slug"
                                                        name="slug"
                                                        value={EDIT_SERVICES.values.slug}
                                                        onChange={EDIT_SERVICES.handleChange}
                                                        onBlur={EDIT_SERVICES.handleBlur}
                                                    />
                                                </div>
                                                <div className="d-block mb-3 ml-1 mt-1">
                                                    {EDIT_SERVICES.errors.slug && EDIT_SERVICES.touched.slug ? (
                                                        <span className="text-danger">{EDIT_SERVICES.errors.slug}</span>
                                                    ) : null}
                                                </div>

                                                <div className="form-group mb-0">
                                                    <label>Description</label>
                                                    <input
                                                        type="text"
                                                        className="form-control col-sm-12 col-md-5"
                                                        placeholder="Enter Description"
                                                        name="description"
                                                        value={EDIT_SERVICES.values.description}
                                                        onChange={EDIT_SERVICES.handleChange}
                                                        onBlur={EDIT_SERVICES.handleBlur}
                                                    />
                                                </div>
                                                <div className="d-block mb-3 ml-1 mt-1">
                                                    {EDIT_SERVICES.errors.description && EDIT_SERVICES.touched.description ? (
                                                        <span className="text-danger">{EDIT_SERVICES.errors.description}</span>
                                                    ) : null}
                                                </div>

                                                <div className="form-group mb-0">
                                                    <label>Long Description</label>
                                                    <textarea
                                                        className="form-control col-sm-12 col-md-5"
                                                        placeholder="Enter Long Description"
                                                        name="long_description"
                                                        value={EDIT_SERVICES.values.long_description}
                                                        onChange={EDIT_SERVICES.handleChange}
                                                        onBlur={EDIT_SERVICES.handleBlur}
                                                        rows={5}
                                                        style={{ resize: 'none' }}
                                                    />
                                                </div>
                                                <div className="d-block mb-3 ml-1 mt-1">
                                                    {EDIT_SERVICES.errors.long_description && EDIT_SERVICES.touched.long_description ? (
                                                        <span className="text-danger">{EDIT_SERVICES.errors.long_description}</span>
                                                    ) : null}
                                                </div>

                                                {Image === "" ?
                                                    <div className="form-group mb-0">
                                                        <label htmlFor="exampleInputFile">Select Image for Services</label>
                                                        <div className="input-group">
                                                            <div className="custom-file">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="exampleInputFile"
                                                                    name="image"
                                                                    onChange={(e: any) => EDIT_SERVICES.setFieldValue("image", e.target.files[0])}
                                                                    accept="image/*"
                                                                />
                                                                <label className="custom-file-label col-sm-12 col-md-5" htmlFor="exampleInputFile">Choose file</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="form-group p-0 col-sm-12 col-md-5" style={{ border: '1px solid lightgray' }}>
                                                        <div className='position-absolute d-flex justify-content-end w-100' style={{ zIndex: 1 }}>
                                                            <button type="button" className="btn close mt-1 mr-2" aria-label="Close" onClick={() => setImage("")}><span aria-hidden="true">×</span></button>
                                                        </div>
                                                        <img className="img-fluid col-sm-12 m-0 p-0" src={Image} alt="Preview_Image" />
                                                    </div>
                                                }
                                            </div>
                                            <div className="card-footer bg-white mt-0 pt-0 mt-3">
                                                <button type="submit" className="btn btn-block btn-primary col-sm-1">UPDATE</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default Services_Edit