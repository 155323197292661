import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoaderStore } from '../../zustand_store/loader';
import { HttpService } from '../../Api/httpService';
import { Update_Portfolio_Category_Schema } from '../../Validation_Schema';

const Portfolio_Category_Edit = () => {

    const navigate = useNavigate();
    const {state} = useLocation();
    const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state:any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
    const [initialValues, setInitialValues] = useState({name: ""});

    useEffect(() => {
        if (state?.id) {
            SET_LOADER_TRUE();
            HttpService.get(`/api/admin/portfolio/edit/${state?.id}`)
              .then((res:any)=> {
                  if (res.status === 200) {
                    setInitialValues({name: res.data.payload.name});
                    SET_LOADER_FALSE();
                  }
              })
              .catch((err:any) => {
                SET_LOADER_FALSE();
                console.log(err.response);
                if (err.response.data) {
                    Swal.fire({
                        title: err.response.data.error,
                        icon: 'error',
                        showConfirmButton: false,
                    });
                }
              });

        } else {
            navigate('/dashboard/portfolio_category');
        }
    }, [SET_LOADER_FALSE, SET_LOADER_TRUE, navigate, state?.id]);

    const EDIT_PORTFOLIO_CATEGORY:any = useFormik({
        initialValues,
        validationSchema: Update_Portfolio_Category_Schema,
        enableReinitialize: true,
        onSubmit: (values:any, action) => {

            SET_LOADER_TRUE();

            const formData = new FormData();
            Object.keys(values).forEach(key => formData.append(key, values[key]));
            
            HttpService.post(`/api/admin/portfolio/update/${state?.id}`,formData)
              .then((res:any) => {
                if(res.status===200){   
                  action.resetForm();
                  SET_LOADER_FALSE();
                  Swal.fire({
                    title: res.data.msg,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  setTimeout(() => {
                    navigate('/dashboard/portfolio_category');
                  }, 2000);
                }
              })
              .catch((err:any) => {
                SET_LOADER_FALSE();
                console.log(err.response);
                if (err.response.data) {
                    Swal.fire({
                        title: err.response.data.error,
                        icon: 'error',
                        showConfirmButton: false,
                    });
                }
              });
        },
    });
    return (
      <>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Portfolio Category Edit</h1>
                </div>
                <div className="col-sm-6 d-flex justify-content-end">
                  <button type='button' className="btn btn-info btn-small" onClick={() => navigate('/dashboard/portfolio_category')}>Back</button>
                </div>
              </div>
            </div>
          </div>

          <section className="content mx-2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={EDIT_PORTFOLIO_CATEGORY.handleSubmit}>
                        <div className="card-body pb-0">

                          <div className="form-group mb-0">
                            <label>Name</label>
                            <input
                              type="text"
                              className="form-control col-sm-12 col-md-5"
                              placeholder="Enter Name"
                              name="name"
                              value={EDIT_PORTFOLIO_CATEGORY.values.name}
                              onChange={EDIT_PORTFOLIO_CATEGORY.handleChange}
                              onBlur={EDIT_PORTFOLIO_CATEGORY.handleBlur}
                            />
                          </div>
                          <div className="d-block mb-3 ml-1 mt-1">
                            {EDIT_PORTFOLIO_CATEGORY.errors.name && EDIT_PORTFOLIO_CATEGORY.touched.name ? (
                              <span className="text-danger">{EDIT_PORTFOLIO_CATEGORY.errors.name}</span>
                            ) : null}
                          </div>

                        </div>

                        <div className="card-footer bg-white mt-0 pt-0">
                          <button type="submit" className="btn btn-block btn-primary col-sm-1">UPDATE</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </>
    )
}
export default Portfolio_Category_Edit